import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import BeforeAfter from "../components/Gallery/BeforeAfter";
// import GalleryContent from "../components/Gallery/GalleryContent";

function Gallery() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Gallery">
      <div className="w-full">
        <TransparentHeader
          headertitle="Gallery"
          Subheader="Gallery"
          bgimg={`${rpdata?.stock?.[1]}`}
        />
                  <div className="py-16 md:mx-[25%] mx-6">
            <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vS98nV6sVMmt7fshGBM5owYGXhFzE3h2-yibewfrIx4GlmOUHDDs3YPORe2XaEzmiBFBu8G3IhBh6Pk/embed?start=false&loop=false&delayms=3000"
              frameborder="0"
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              className=" md:w-[800px] md:h-[500px] w-[380px] h-[250px]"
              title="presentationpoint"
            ></iframe>
          </div>
        {rpdata?.beforeAfterGall?.length > 0 ? <BeforeAfter /> : null}
        {/* <GalleryComponent /> */}
        {/* <div>
          {rpdata?.landings?.length > 0 ? (
            rpdata?.landings?.map((item, index) => {
              return (
                <div className="p- 20 pb-32">
                  <div className="text-center py-5" key={index}>
                    <h2 className="text-center">
                      {rpdata?.labels?.general?.titleGallery}
                    </h2>
                    <h2 className="capitalize">{item.name}</h2>
                  </div>
                  <GalleryContent galleryImages={item.gallery} />
                </div>
              );
            })
          ) : (
            <div className="pb-32">
              <div className="text-center py-5">
                <h2 className="text-center pt-[100px]">
                  {rpdata?.labels?.general?.titleGallery}
                </h2>
              </div>
              <GalleryContent
                galleryImages={
                  rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock
                }
              />
            </div>
          )}
        </div> */}
      </div>
    </BaseLayout>
  );
}

export default Gallery;