import React, { useContext } from "react";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import { GlobalDataContext } from "../context/context";

function PrivacyPolicy() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Delivery Messege Contract">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="DELIVERY MESSEGE CONTRACT"
          Subheader="DELIVERY MESSEGE CONTRACT"
          bgimg={`${rpdata?.stock?.[1]}`}
        />
        </div>
        <div className="w-full my-20">
          <div
            className="bg-cover bg-center bg-opacity-40 md:w-[70%] w-[90%] mx-auto bg-no-repeat bg-fixed"
            style={{ backgroundImage: `url("${rpdata?.dbPrincipal?.logo}")` }}
          >
            <div className="w-full h-full bg-white bg-opacity-90">
              <h2 className="text-[42px] font-bold text-center">
              DELIVERY MESSEGE CONTRACT
              </h2>
              <div>
                {rpdata?.works?.map((item, index) => (
                  <div key={index}>
                    <h3 className="text-[32px] font-bold text-center">
                      {item.name}
                    </h3>
                    <p className="text-[18px] text-center">
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
    </BaseLayout>
  );
}

export default PrivacyPolicy;